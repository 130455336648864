<template>
  <div class="bg">
    <van-tabs v-model="active"
              sticky
              animated
              class="mytab">
      <van-tab title="问诊中">
        <van-pull-refresh v-model="refreshing"
                          @refresh="onRefresh">
          <van-list v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
            <div v-for="item in list" @click="goinquirying"
                 class="mylist"
                 :key="item">
              <div class="listtop">
                <div class="cl">
                  <img src="https://img.yzcdn.cn/vant/apple-1.jpg"
                       class="fl"
                       alt="">
                  <div class="fl">

                    <p>王林</p>
                    <p class="em">男 46岁</p>
                  </div>
                </div>
                <div class="top_right">
                  <span class="em">3分钟前</span>
                  <span class="zt"
                        v-show="active == 0 ">接诊中</span>
                  <span class="zt on"
                        v-show="active == 1 ">已结束</span>

                </div>
              </div>
              <div class="listbot sl">
                <span class="h1">病情描述:</span>最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="已结束">
        <van-pull-refresh v-model="refreshing"
                          @refresh="onRefresh">
          <van-list v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad">
            <div v-for="item in list"
                 class="mylist"
                 :key="item">
              <div class="listtop">
                <div class="cl">
                  <img src="https://img.yzcdn.cn/vant/apple-1.jpg"
                       class="fl"
                       alt="">
                  <div class="fl">
                    <p>王林</p>
                    <p class="em">男 46岁</p>
                  </div>
                </div>
                <div class="top_right">
                  <span class="em">3分钟前</span>
                  <span class="zt"
                        v-show="active == 0 ">接诊中</span>
                  <span class="zt on"
                        v-show="active == 1 ">已结束</span>

                </div>
              </div>
              <div class="listbot sl">
                <span class="h1">病情描述:</span>最近一个月总是头晕、头痛、疲劳、心悸等，有时还会出现注意力不集中、记忆力减退、肢体麻木的现象
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

  </div>
</template>
<script>
export default {
  data() {
    return {
      active: '',
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  mounted() { },
  methods: {
       // 问诊页面
    goinquirying(){
      this.$router.push({name:'inquirying'})

    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  }
}
</script>
<style  scoped>
/deep/.mytab .van-tab--active .van-tab__text {
  color: rgba(255, 255, 255, 1);
}
/deep/.mytab .van-tab--active .van-tab {
  border: none;
  box-sizing: border-box;
}

/deep/.mytab .van-tab {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
}
/deep/.mytab .van-tab__text {
  z-index: 10;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
}
/deep/.mytab .van-tabs__line {
  width: 50%;
  height: 100%;
  background: #1daefc;
}
</style>
<style lang="less" scoped>
.mylist {
  .listbot {
    font-size: 14px;
    text-align: left;
    margin-top: 15px;
  }
  .top_right {
    .em {
      margin-right: 15px;
    }
    .zt {
      font-size: 14px;
      color: rgba(29, 174, 252, 1);
    }
    .zt.on {
      font-size: 14px;
      color: #d8d8d8;
    }
  }
  width: 100%;
  background: #ffffff;
  height: 154px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 16px;
  .listtop {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    p {
      text-align: left;
      margin-bottom: 5px;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
.mytab {
  width: 375px;
  height: 48px;
  box-sizing: border-box;
  .van-tab--active {
    .van-tab__text {
      color: rgba(255, 255, 255, 1);
    }
    .van-tab {
      border: none;
      box-sizing: border-box;
    }
  }
  .van-tab {
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
  }
  .van-tab__text {
    z-index: 10;
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
  }
  .van-tabs__line {
    width: 50%;
    height: 100%;
    background: #1daefc;
  }
}
</style>